import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Iframe from "react-iframe";

const AddressInfo = () => {
  return (
    <div className="info-item">
      <h2>
        <div
          style={{
            fontSize: "inherit",
            color: "inherit",
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <AccountBalanceOutlinedIcon /> Adresimiz
        </div>
      </h2>
      <div className="info-address">
        <div className="info-address-text">
          <span>İYZİ BİLİŞİM A.Ş</span>
          <br />
          <span>Koza Mah. 1644. Sok. No: 27</span>
          <span>Bahçeşehir Mobilyacılar Sitesi</span>
          <span>34519 ESENYURT / İSTANBUL</span>
        </div>
        <Iframe
          className="info-address-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d751.9930650051812!2d28.6605233759647!3d41.06959900466649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b5589a9cac372d%3A0x47a3e886ca6fd699!2sKoza%2C%201644.%20Sk.%20No%3A27%2C%2034538%20Esenyurt%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1735841292593!5m2!1str!2str"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="iyziShip"
        />
      </div>
    </div>
  );
};

export default AddressInfo;
