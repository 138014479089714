import { Box, Button, Modal, createTheme, ThemeProvider } from "@mui/material";
import { useRecoilState } from "recoil";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import {
  parcelReturnOpenState,
  returnShipmentInformationOpenState,
  returnShipmentPaymentOpenState,
} from "../../../atoms/returnShipment";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const ReturnShipmentInformation = ({ handleShipmentPaymentClose2 }) => {
  const navigate = useNavigate();
  const [returnShipmentInformationOpen, setReturnShipmentInformationOpen] = useRecoilState(
    returnShipmentInformationOpenState
  );
  const [, setReturnShipmentPaymentOpen] = useRecoilState(returnShipmentPaymentOpenState);
  const [, setParcelReturnOpen] = useRecoilState(parcelReturnOpenState);
  let modalType = returnShipmentInformationOpen.modalType;
  const renderModalContent = () => {
    const iyziShipAddress =
      "Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL";
    const textIyziShipAddress =
      "İade işleminiz tamamlanmıştır.Gönderinizi aşağıdaki adresimizden teslim alabilirsiniz";
    const textReturnCourier =
      "İade işleminiz tamamlanmıştır.Gönderiniz yola çıktığında bilgilendirileceksiniz.";
    const textReturnNavlun = "NAVLUNUNUZ CÜZDANINIZA İADE EDİLMİŞTİR.";
    const textMail = "muhasebe@iyziship.com";
    const textClose = "Kapat";
    const textRead = "Okudum, anladım";

    if (modalType.includes("returnSupplier")) {
      modalType = modalType.replace("Free", "");
      modalType = modalType.replace("Notfree", "");
    }
    const content = {
      paymentSuccessful: {
        text: 'Yurt dışı dönüş masrafı alınmıştır. "Gönderinizi Nasıl İade Edelim ?" sayfasına ilerleyeceksiniz.',
        buttonText: textRead,
        buttonClick: handlePriceInformation,
      },
      collectionAddress: {
        text: "Kuryemizin size ulaştırabilmesi için toplama adresi eklemeniz gerekmektedir.",
        buttonText: textRead,
        buttonClick: handleNavigateCollectionAddress,
      },
      returnWarehouseUnpaidFreeFrontdesk: {
        text: textIyziShipAddress,
        text2: iyziShipAddress,
        buttonText: textClose,
        buttonClick: handleCloseReload,
      },
      returnWarehouseUnpaidFreeWarehouseCourier: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleCloseReload,
      },
      returnWarehouseUnpaidNotfreeFrontdesk: {
        text: textIyziShipAddress,
        text2: iyziShipAddress,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnWarehouseUnpaidNotfreeWarehouseCourier: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnWarehouseUnpaidNotfreeDomestic: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnWarehouseUnpaidNewParcel: {
        text: "GÖNDERİ OLUŞTUR AŞAMASINA YÖNLENDİRİLİYORSUNUZ",
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnWarehousePaidFreeFrontdesk: {
        text: textReturnNavlun,
        text2: textIyziShipAddress,
        text3: iyziShipAddress,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnWarehousePaidFreeWarehouseCourier: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnWarehousePaidNotfreeFrontdesk: {
        text: textReturnNavlun,
        text2: textIyziShipAddress,
        text3: iyziShipAddress,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnWarehousePaidNotfreeWarehouseCourier: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnWarehousePaidNotfreeDomestic: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnWarehousePaidNewParcel: {
        text: textReturnNavlun,
        text2: "GÖNDERİ OLUŞTURMA SAYFASINA YÖNLENDİRİLECEKSİNİZ.",
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnSupplierPaidNotinvoiceNewParcel: {
        text: textReturnNavlun,
        text2: "GÖNDERİ OLUŞTURMA SAYFASINA YÖNLENDİRİLECEKSİNİZ.",
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnSupplierPaidInvoiceIndividualNewParcel: {
        text: textReturnNavlun,
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnSupplierPaidInvoiceCompanyNewParcel: {
        text: textReturnNavlun,
        text2:
          "Cüzdanınızdaki paranızı çekmek istediğinizde iade faturası gelmemiş gönderiniz tespit edilmesi durumunda cüzdan iadeniz gecikebilir Bu sebeple Cüzdanınıza aktarılmış olan ..... TL tutar kadar KDV siz olarak bizlere iade faturası düzenleyerek aşağıdaki mail adresine göndermenizi önemle rica ediyoruz.",
        text3: textMail,
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnSupplierPaidNotinvoiceFrontdesk: {
        text: textReturnNavlun,
        text2: textIyziShipAddress,
        text3: iyziShipAddress,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidNotinvoiceWarehouseCourier: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidNotinvoiceDomestic: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceIndividualFrontdesk: {
        text: textReturnNavlun,
        text2: textIyziShipAddress,
        text3: iyziShipAddress,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceIndividualWarehouseCourier: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceIndividualDomestic: {
        text: textReturnNavlun,
        text2: textReturnCourier,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceCompanyFrontdesk: {
        text: textReturnNavlun + " " + textIyziShipAddress,
        text2: iyziShipAddress,
        text3:
          "Cüzdanınızdaki paranızı çekmek istediğinizde iade faturası gelmemiş gönderiniz tespit edilmesi durumunda cüzdan iadeniz gecikebilir Bu sebeple Cüzdanınıza aktarılmış olan ..... TL tutar kadar KDV siz olarak bizlere iade faturası düzenleyerek aşağıdaki mail adresine göndermenizi önemle rica ediyoruz.",
        text4: textMail,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceCompanyWarehouseCourier: {
        text: textReturnNavlun + " " + textReturnCourier,
        text2:
          "Cüzdanınızdaki paranızı çekmek istediğinizde iade faturası gelmemiş gönderiniz tespit edilmesi durumunda cüzdan iadeniz gecikebilir Bu sebeple Cüzdanınıza aktarılmış olan ..... TL tutar kadar KDV siz olarak bizlere iade faturası düzenleyerek aşağıdaki mail adresine göndermenizi önemle rica ediyoruz.",
        text3: textMail,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },
      returnSupplierPaidInvoiceCompanyDomestic: {
        text: textReturnNavlun + " " + textReturnCourier,
        text2:
          "Cüzdanınızdaki paranızı çekmek istediğinizde iade faturası gelmemiş gönderiniz tespit edilmesi durumunda cüzdan iadeniz gecikebilir Bu sebeple Cüzdanınıza aktarılmış olan ..... TL tutar kadar KDV siz olarak bizlere iade faturası düzenleyerek aşağıdaki mail adresine göndermenizi önemle rica ediyoruz.",
        text3: textMail,
        buttonText: textRead,
        buttonClick: handleCloseReload,
      },

      returnAbroadUnpaidNotfreeFrontdesk: {
        text: textIyziShipAddress,
        text2: iyziShipAddress,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnAbroadUnpaidNotfreeWarehouseCourier: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnAbroadUnpaidNotfreeDomestic: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnAbroadPaidNewParcel: {
        text: "GÖNDERİ OLUŞTUR AŞAMASINA YÖNLENDİRİLİYORSUNUZ",
        buttonText: textRead,
        buttonClick: handleNavigateCreateShipment,
      },
      returnAbroadPaidNotfreeDomestic: {
        text: textReturnCourier,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnAbroadPaidFreeFrontdesk: {
        text: textIyziShipAddress,
        text2: iyziShipAddress,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
      returnAbroadPaidFreeWarehouse: {
        text: textIyziShipAddress,
        text2: iyziShipAddress,
        buttonText: textClose,
        buttonClick: handleClosePayment,
      },
    };
    return (
      <>
        <span style={{ display: "block" }}>{content[modalType]?.text}</span>
        <span style={{ display: "block" }}>{content[modalType]?.text2}</span>
        <span style={{ display: "block" }}>{content[modalType]?.text3}</span>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "var(--gap)" }}>
          <Button
            onClick={content[modalType]?.buttonClick}
            sx={{
              backgroundColor: "var(--success)",
              color: "var(--lighterBg)",
              textTransform: "none",
              transition: "all 150ms linear",
              borderRadius: "var(--buttonBorderRadius)",
              padding: "7px 16px",
              ":hover": {
                backgroundColor: "var(--blueBrand)",
                transition: "all 150ms linear",
              },
            }}
          >
            {" "}
            {content[modalType]?.buttonText}
          </Button>
        </Box>
      </>
    );
  };
  const handleClose = () => {
    setReturnShipmentInformationOpen({ status: false, modalType: "" });
  };
  const handleCloseReload = () => {
    handleClose();
    window.location.reload(true);
  };
  const handleNavigateCreateShipment = () => {
    handleClose();
    navigate("/create-shipment");
  };
  const handlePriceInformation = () => {
    handleClose();
    handleShipmentPaymentClose2();
    setParcelReturnOpen(true);
  };
  const handleNavigateCollectionAddress = () => {
    handleClose();
    navigate("/profile-settings/address-info");
  };
  const handleClosePayment = () => {
    handleCloseReload();
    setReturnShipmentPaymentOpen(false);
  };

  return modalType.includes("returnWarehouseUnpaidNotfree") ||
    modalType.includes("paymentSuccessful") ||
    modalType.includes("returnAbroadPaidNotfreeDomestic") ? (
    renderModalContent()
  ) : (
    <ThemeProvider theme={theme}>
      <Modal open={returnShipmentInformationOpen.status} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "400px",
            margin: "auto",
            position: "relative",
          }}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          {renderModalContent()}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ReturnShipmentInformation;
