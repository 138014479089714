import { Button, Modal } from "@mui/material";
import { useEffect } from "react";

const ChangePasswordInfo = ({ open, setOpen }) => {
  useEffect(() => {
    const isClosed = localStorage.getItem('changeClosed-address');
    if (isClosed === '1') {
      setOpen(false);
    }
  }, [setOpen]);

  const handleClose = () => {
    localStorage.setItem('changeClosed-address', '1');
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        zIndex: 9999,
        outline: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "var(--gap)",
          backgroundColor: "var(--lighterBg)",
          maxWidth: "690px",
          width: "100%",
          overflow: "auto",
          borderRadius: "var(--wrapperBorderRadius)",
          outline: "none",
          maxHeight: "90vh",
          padding: "20px"
        }}
      >
        {/* <figure
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--pastelBlue)",
          }}
        >
          <img
            src="/images/heros/heros.png"
            alt="iyziTeam"
            style={{ width: "100%" }}
          />
        </figure> */}
        <div
          style={{ padding: "10px", display: "flex", flexDirection: "column", gap: "var(--gap)" }}
        >
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "24px",
              color: "var(--btnBgBlue)",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            Değerli İyziShip Kullanıcıları,
          </h2>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
          Depo adresimiz değişmiştir.
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
          Yeni Adresimiz:
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
          Koza Mah. 1644. Sok. No: 27 34519 ESENYURT / İSTANBUL
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            Saygılarımızla,
          </p>
          <p style={{ fontSize: "14px", color: "var(--text)", textAlign: "center" }}>
            iyziTeam
          </p>
          <Button className="iyzi-button-blue" onClick={handleClose}>
            Tamam
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordInfo;
