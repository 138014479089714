import {
  Button,
  Divider,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import emailIcon from "../../img/logos/emailIcon.svg";
import konumIcon from "../../img/logos/konumIcon.svg";
import iletisimIcon from "../../img/logos/iletisimIcon.svg";
import Icon1 from "../../img/images/Group_1.png";
import Icon2 from "../../img/images/Group_2.png";
import Icon3 from "../../img/images/Group_3.png";
import Icon4 from "../../img/images/Group_4.png";
import Icon5 from "../../img/images/Group_5.png";
import Icon6 from "../../img/images/Group_6.png";
import tio from "../../json/iyziship-tio.json";
import axios from "axios";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "30px !important",
          height: "30px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            height: "0px",
          },
        },
      },
    },
  },
});

const Footer = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [mailText, setMailText] = useState("");
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_KEY;
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: email,
    };

    await axios
      .post(`${apiUrl}subscription/store`, data)
      .then((response) => {
        setLoading(false);
        setSubmitted(true);
        setSubmittedEmail(true);
        setMailText("Email adresiniz başarıyla kaydedildi.");
        toast.success(response.data.message, { autoClose: 2000 });
        setEmail("");
      })
      .catch((error) => {
        setLoading(false);
        setSubmitted(true);
        setSubmittedEmail(true);
        setMailText(error.response.data.errors.email[0]);
        Errors(error);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const base64ToUint8Array = (base64) => {
    let binaryString = window.atob(base64);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const handleOpenTio = () => {
    let data = base64ToUint8Array(tio.file.data);
    let blob = new Blob([data], {
      type: tio.file.mime,
    });
    let image = window.URL.createObjectURL(blob);

    let openedWindow = window.open(image, "_blank");

    // Pop-up engelleyicisini kontrol et
    if (!openedWindow) {
      alert(
        "Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın."
      );
      return;
    }

    // Pop-up penceresi kapandığında blob URL'yi temizle
    openedWindow.onbeforeunload = function () {
      window.URL.revokeObjectURL(image);
    };
  };

  const footerLinks = [
    {
      title: "Faydalı Linkler",
      links: [
        { title: "Nasıl Çalışır", url: "/nasil-calisir" },
        { title: "Entegrasyonlar", url: "/entegrasyonlar" },
        { title: "Fiyatlandırma", url: "/fiyatlandirma" },
        { title: "S.S.S", url: "/sss" },
        { title: "Blog", url: "https://blog.iyziship.com", target: "_blank" },
        { title: "İletişim", url: "/iletisim" },
      ],
    },
    {
      title: "Kurumsal",
      links: [
        { title: "Hakkımızda", url: "/hakkimizda" },
        { title: "iyziTeam", url: "/iyziteam" },
        { title: "Taşıma Belgesi", url: "/" },
      ],
      title2: "Anlaşmalı Kargolar",
      links2: [
        { title: "Aras Kargo: 2206354550828", url: "/" },
        { title: "Sürat Kargo: 1316232654", url: "/" },
        { title: "MNG Kargo: 841699904", url: "/" },
      ],
    },
    {
      title: "Gizlilik ve Kullanım",
      links: [
        { title: "Kullanıcı Sözleşmesi", url: "/kullanim-kosullari" },
        { title: "İptal ve İadeler", url: "/iptal-ve-iadeler" },
        { title: "Çerez Politikası", url: "/cerez-politikasi" },
        { title: "Gizlilik Politikası", url: "/gizlilik-politikasi" },
        { title: "KVKK Aydınlatma Metni", url: "/kvkk" },
      ],
    },
    {
      title: "İletişim",
      links: [
        {
          title: "0850 532 49 94",
          url: "tel:08505324994",
          icon: iletisimIcon,
        },
        {
          title: "info@iyziship.com",
          url: "mailto:info@iyziship.com",
          icon: emailIcon,
        },
        {
          title:
            "Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL",
          url: "https://www.google.com/maps?ll=41.027673,28.812437&z=16&t=m&hl=tr&gl=TR&mapclient=embed&cid=5123811732219466227",
          icon: konumIcon,
        },
      ],
      title2: "Bizi Takip Edin",
      links2: [
        { title: "", icon: Icon1, url: "https://www.facebook.com/iyziship" },
        { title: "", icon: Icon2, url: "https://www.instagram.com/iyziship/" },
        { title: "", icon: Icon3, url: "https://twitter.com/iyziship" },
        { title: "", icon: Icon4, url: "https://www.tiktok.com/@iyziship" },
        {
          title: "",
          icon: Icon5,
          url: "https://www.linkedin.com/company/iyziship/",
        },
        { title: "", icon: Icon6, url: "https://www.youtube.com/@iyziship" },
      ],
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <footer className="iyzi-footer">
        <div className="footer">
          <div className="footer-mail">
            <div className="footer-mail-text">
              <p>E-Bültenimize abone olun!</p>
              <p>
                Özel kampanyalar, duyurular ve tüm gelişmelerden anında haberdar
                olun.
              </p>
            </div>
            <form className="footer-mail-form" onSubmit={handleSendEmail}>
              <div>
                {submittedEmail ? (
                  submitted ? (
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                    >
                      {mailText}
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "white",
                        fontSize: "12px",

                        textTransform: "capitalize",
                      }}
                    >
                      {mailText}
                    </p>
                  )
                ) : (
                  ""
                )}
              </div>
              <TextField
                className="footer-mail-form-field"
                type="email"
                id="outlined-basic"
                variant="outlined"
                value={email}
                onChange={handleChangeEmail}
                label="E-Posta Giriniz"
                size="small"
                required
                name="email"
                sx={{
                  "& label.Mui-focused": {
                    color: "white",
                    marginTop: "-5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />

              {loading ? (
                <div
                  style={{
                    width: "64px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    sx={{ width: "21px !important", height: "21px !important" }}
                  />
                </div>
              ) : (
                <Button type="submit" className="iyzi-button-blue">
                  Abone Ol
                </Button>
              )}
            </form>
          </div>
          <Divider
            className="footer-divider"
            sx={{ borderColor: "rgba(255, 255, 255, 0.20)" }}
          />
          <div className="footer-links">
            {footerLinks.map((footerLink, key) => (
              <div key={key} className="footer-gruop">
                <h3>{footerLink.title}</h3>
                {footerLink.links.map((item, subkey) =>
                  item.title === "Taşıma Belgesi" ? (
                    <div
                      key={subkey}
                      onClick={handleOpenTio}
                      style={{ cursor: "pointer" }}
                      className="bottom-links"
                    >
                      {item.title}
                    </div>
                  ) : (
                    <Link
                      key={subkey}
                      to={item.url}
                      className={
                        footerLink.title === "İletişim"
                          ? "bottom-links iletisim"
                          : "bottom-links"
                      }
                      target={item.target && item.target}
                    >
                      {item?.icon && (
                        <img
                          src={item.icon}
                          alt={`${item.title} ikonu`}
                          style={{ marginRight: "8px" }}
                        />
                      )}{" "}
                      <p>{item.title}</p>
                    </Link>
                  )
                )}
                <div>
                  {footerLink?.title2 && (
                    <>
                      <h3>{footerLink.title2}</h3>
                      {footerLink.links2.map((item, sub2key) =>
                        footerLink.title2 === "Anlaşmalı Kargolar" ? (
                          <div
                            key={sub2key}
                            style={{
                              marginTop: "12px",
                              cursor: "pointer",
                            }}
                            className="bottom-links"
                          >
                            {item.title}
                          </div>
                        ) : (
                          <Link
                            key={sub2key}
                            to={item?.url}
                            className="bottom-links"
                            style={{
                              marginTop: "12px",
                            }}
                          >
                            {item?.icon && (
                              <img
                                src={item.icon}
                                alt={`${item.title} ikonu`}
                                width={30}
                                style={{
                                  marginRight: "5px",
                                  marginTop: "12px",
                                }}
                              />
                            )}{" "}
                          </Link>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Divider
            className="footer-divider"
            sx={{ borderColor: "rgba(255, 255, 255, 0.20)" }}
          />
          <div className="footer-copyright">
            <Link to={"/"}>
              <img
                width={144}
                src="/images/home/logo-2.svg"
                alt="IyziShip Logo"
              />
            </Link>
            <Link to={"https://www.iyzico.com/"}>
              <img
                width={144}
                src="/images/home/iyzico.svg"
                alt="IyziShip Logo"
              />
            </Link>
            <Link to={"https://www.iyzico.com/"}>
              <img
                height={60}
                src="/images/home/mastercard.svg"
                alt="IyziShip Logo"
              />
            </Link>
            <Link to={"https://www.iyzico.com/"}>
              <img
                width={80}
                src="/images/home/visa.svg"
                alt="IyziShip Logo"
              />
            </Link>

            <p style={{ fontWeight: 400 }}>
              © iyziShip 2014-2024 | Tüm Hakları Saklıdır.
            </p>
          </div>
        </div>
      </footer>
    </ThemeProvider>
  );
};

export default Footer;
