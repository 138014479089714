import { Link } from "react-router-dom";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const Kvkk = () => {
  DynamicTitle("KVKK | iyziShip");

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <section className="policies">
            <article>
              <h1>KİŞİSEL VERİLERİN KORUNMASI KANUNU AYDINLATMA METNİ</h1>
              <p>
                İşbu Kişisel Verilerin İşlenmesi ve Korunması Hakkında
                Aydınlatma Metni (“Aydınlatma Metni”) İYZİ BİLİŞİM ANONİM
                ŞİRKETİ (“Şirket”) tarafından, siz veri sahiplerine karşı, 6698
                Sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) gereğince
                kişisel verilerin korunması kapsamında öngörülen aydınlatma
                yükümlülüğümüzü yerine getirmek amacıyla düzenlenmiştir.{" "}
              </p>
              <p>
                İşbu Aydınlatma Metni ile kişisel verilerinizin işlenmesi
                aşamasında şirketimizin uyması gereken prosedür ile sahip
                olduğunuz hak ve özgürlükleriniz açıklanmıştır.
              </p>
            </article>
            <article>
              <h4>
                Kişisel Verilerin İşlenmesinin Yasal Dayanağı Nedir ve Hangi
                Amaçla İşlenmektedir?
              </h4>
              <p>
                6698 sayılı KVKK’nın 4., 5. ve 6. maddeleri uyarınca kişisel
                verileriniz; sunacağımız hizmet çerçevesinde sizden alacağı
                talimatlar ve başvurular kapsamında, yasal mevzuattan
                kaynaklanan yükümlülükler ve sınırlar çerçevesinde, sunulan
                hizmetlerden faydalanabilmeniz ve sorunsuz olarak
                yararlanabilmeniz, sözleşme kapsamındaki hizmetleri sunabilmek,
                sözleşme süresince satın almakta olduğunuz hizmete ilişkin
                gerekli bilgilendirmeleri yapmak, ilgili kampanyalardan sizi
                haberdar etmek, bilgi saklama, raporlama, bilgilendirme
                yükümlülüklerine uymak amacıyla ve kimlik bilgileri, iletişim
                bilgileri, hukuki işlem ve uyum bilgileri, talep ve şikayet
                yönetimi bilgileri, görsel ve işitsel veriler, müşteri verileri
                ve müşteri işlem verileri gibi kişisel ve özel nitelikli kişisel
                verilerinizi elde etme, kaydetme, saklama, güncelleme,
                sınıflandırma, mevzuatın izin verdiği üçüncü kişilerle paylaşma
                veya onlara devretme gibi konularda, “Veri İşleme” olarak kabul
                edilen işlemleri KVKK çerçevesinde ve kişisel veri işleme
                şartları dahilinde gerçekleştirebilir. Bu sebeple kişisel
                verileriniz; sözlü veya elektronik ortamlarda işlemlerinizi
                tamamlayabilmeniz için gerekli tüm kayıt ve belgelerin
                düzenlenebilmesi, kurumsal sürdürülebilirlik, kurumsal yönetim,
                stratejik planlama ve bilgi güvenliği süreçlerinin
                planlanabilmesi, denetlenmesi, hayata geçirilmesi ve idari ve
                hukuki yükümlülüklerimizin gereğinin yerine getirilmesi gibi
                amaçlarla işlenebilir. Bu nedenle kişisel verileriniz yasaların
                öngördüğü sınırlandırmalar çerçevesinde, Şirketimiz ürün ve
                hizmetlerinin sunulabilmesi, bu konuda aldığınız/alacağınız ürün
                ve hizmete ilişkin iletişim kurulabilmesi, ayrıca bu konuda izin
                vermiş olmanız durumunda pazarlama faaliyetlerinde
                kullanılabilmesi, ürün/hizmet teklifi, modelleme, raporlama,
                skorlama, risk izleme, istihbarat, Şirketimiz ve
                iştiraklerimizin mevcut veya yeni ürün çalışmaları ve potansiyel
                müşteri tespiti vb. amaçlar ile sermaye piyasası faaliyetleri
                ile ilişkili olarak işlenmektedir. Toplanan kişisel verileriniz,
                Şirketimiz ve iştiraklerimiz tarafından sunulan ürün ve
                hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş
                birimlerimiz tarafından yapılması, Şirketimiz ve iştiraklerimiz
                tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım
                alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere
                önerilmesi, Şirketimizin, iştiraklerimizin ve Şirketimizle iş
                ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin
                temini (Şirketimiz tarafından yürütülen iletişime yönelik idari
                operasyonlar, Şirkete ait lokasyonların fiziksel güvenliğini ve
                denetimini sağlamak, değerlendirme/şikayet yönetimi süreçleri,
                itibar araştırma süreçleri, etkinlik yönetimi, hukuki uyum
                süreci, denetim, mali işler v.b.), Şirketimizin ticari ve iş
                stratejilerinin belirlenmesi ve uygulanması ve Şirketimizin
                insan kaynakları politikalarının yürütülmesinin temini
                amaçlarıyla KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen
                kişisel veri işleme şartları ve amaçları dahilinde
                işlenebilecektir.
              </p>
            </article>
            <article>
              <h4>
                İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                Aktarılabileceği
              </h4>
              <p>
                Toplanan kişisel verileriniz; Şirketimiz ve iştiraklerimiz
                tarafından sunulan ürün ve hizmetlerden daha iyi faydalanmanız
                için gerekli çalışmaların iş birimlerimiz tarafından yapılması,
                Şirketimiz ve Şirketimizin yurt içi ve yurt dışındaki doğrudan
                veya dolaylı hissedarları, iştirakleri, ana ortakları ve grup
                şirketleriyle, sözleşme imzaladığı program ortaklarıyla, ortak
                marka çalışması yaptığı üçüncü kişilerle ve hizmetlerinin
                sunulabilmesi için paylaşımın gerekli olabileceği ve mevzuatta
                izin verilen kişiler, kuruluşlar, kamu tüzel kişileri gibi kişi
                ve kuruluşlarla, yasal düzenlemelerin izin verdiği diğer kişi
                kurum ve kuruluşlarla, hizmet aldığı yurt içi ve yurt dışındaki
                diğer üçüncü kişilerle kişisel verilerin korunmasına ilişkin
                mevzuatın öngördüğü esaslar kapsamında paylaşılabilir.
              </p>
            </article>
            <article>
              <h4>
                Kişisel Veri Sahibinin KVK Kanunu’nun 11. Maddesinde Sayılan
                Hakları Nelerdir?
              </h4>
              <p>
                KVK Kanunu uyarınca, kişisel verileriniz işlenmişse buna ilişkin
                bilgi talep etme, kişisel verilerinizin işlenme amacını ve
                bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt
                içinde veya yurt dışında kişisel verilerinizin aktarıldığı
                üçüncü kişileri bilme, kişisel verilerinizin eksik veya yanlış
                işlenmiş olması hâlinde bunların düzeltilmesini isteme, kişisel
                verilerinizin silinmesini veya yok edilmesini isteme, kişisel
                verilerinizin düzeltilmesi, silinmesi veya yok edilmesi halinde
                bu işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere
                bildirilmesini isteme, işlenen kişisel verilerinizin münhasıran
                otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
                aleyhinizde bir sonucun ortaya çıkmasına itiraz etme ve kişisel
                verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara
                uğraması halinde zararın giderilmesini talep etme haklarınız
                mevcuttur. Kişisel veri sahibi olarak sahip olduğunuz ve
                yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve
                kullanmayı talep ettiğiniz hakka ilişkin açıklamalarınızı içeren
                başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması,
                talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası
                adına hareket ediyor iseniz bu konuda özel olarak yetkili
                olmanız ve yetkinizin belgelendirilmesi, başvurunun kimlik ve
                adres bilgilerini içermesi ve başvuruya kimliğinizi teşvik edici
                belgelerin eklenmesi gerekmektedir. Kişisel veri sahipleri
                olarak, haklarınıza ilişkin taleplerinizi Şirketimize
                info@iyziship.com adresine e-posta göndererek iletebilirsiniz.
                İletmeniz durumunda Şirket talebin niteliğine göre talebi en
                kısa sürede ve en geç otuz (30) gün içinde sonuçlandırılacaktır.
                Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Şirket
                tarafından Kişisel Verileri Koruma Kurulu tarafından belirlenen
                tarifedeki ücret alınacaktır.
              </p>
            </article>
            <article>
              <h4>Veriler Hangi Süreler ile Saklanacaktır?</h4>
              <p>
                Kişisel verileriniz, işbu Aydınlatma Metni ile size bildirilen
                amaçlar dışında kullanılmamak kaydıyla işlenecek olup, ilgili
                mevzuatta öngörülen yasal saklama süreleri boyunca muhafaza
                edilecektir. Bu sürelerin sonunda KVKK’nın 7. maddesi uyarınca
                resmen ya da talebiniz üzerine veriler silinecek, yok edilecek
                ya da anonim hale getirilecektir.
              </p>
            </article>
            <article>
              <p className="company-info">
                <span>Ticaret Ünvanı:</span> İYZİ BİLİŞİM ANONİM ŞİRKETİ
              </p>
              <p className="company-info">
                <span>Adresi:</span>{" "}
                <a
                  href="https://goo.gl/maps/qNRaDs7V4fi5CtW2A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                  Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL
                  </span>
                </a>
              </p>
              <p className="company-info">
                <span>Telefon:</span> +90 (850) 532 49 94
              </p>
              <p className="company-info">
                <span>Fax:</span> +90 (212) 695 0 959
              </p>
              <p className="company-info">
                <span>E-posta:</span>{" "}
                <a rel="noreferrer" href="mailto:info@iyziship.com">
                  info@iyziship.com
                </a>
              </p>
              <p className="company-info">
                <span>Web sitesi:</span> <Link to={"/"}>www.iyziship.com</Link>
              </p>
            </article>
          </section>
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default Kvkk;
