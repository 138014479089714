import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <a
        href="https://goo.gl/maps/qNRaDs7V4fi5CtW2A"
        target="_blank"
        rel="noreferrer"
      >
        <LocationOnIcon
          sx={{ width: "50px", height: "50px", color: "var(--btnBgBlue)" }}
        />
        <span>
        Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL
        </span>
      </a>
      <a href="mailto:info@iyziship.com" target="_blank" rel="noreferrer">
        <EmailIcon
          sx={{ width: "50px", height: "50px", color: "var(--btnBgBlue)" }}
        />
        <span>info@iyziship.com</span>
      </a>
      <a href="tel:+908505324994" target="_blank" rel="noreferrer">
        <SupportAgentIcon
          sx={{ width: "50px", height: "50px", color: "var(--btnBgBlue)" }}
        />
        <span>+90 (850) 532 49 94</span>
      </a>
    </div>
  );
};

export default ContactInfo;
