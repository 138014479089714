import { Link } from "react-router-dom";
import Footer from "../components/beforeLogin/Footer";
import Header from "../components/beforeLogin/Header";
import { DynamicTitle } from "../utils/DynamicTitle";
// import PageTransition from "../utils/PageTransition";

const PrivacyPolicy = () => {
  DynamicTitle("Gizlilik Politikası | iyziShip");

  return (
    <>
      {/* <PageTransition> */}
        <Header />
        <main className="iyzi-main">
          <section className="policies">
            <article>
              <h1>Gizlilik Politikası</h1>
              <p>
                Güvenliğiniz bizim için önemli. Bu sebeple bizimle
                paylaşacağınız kişisel verileriniz hassasiyetle korunmaktadır.
              </p>
              <p>
                Biz, İYZİ BİLİŞİM ANONİM ŞİRKETİ, veri sorumlusu olarak, bu
                gizlilik ve kişisel verilerin korunması politikası ile, hangi
                kişisel verilerinizin hangi amaçla işleneceği, işlenen verilerin
                kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve
                hukuki sebeplerimiz ile; işlenen verilerinize ilişkin
                haklarınızın neler olduğu hususunda sizleri aydınlatmayı
                amaçlıyoruz.
              </p>
            </article>
            <article>
              <h4>
                Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi
              </h4>
              <p>
                IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece analiz
                yapmak amacıyla ve çerezler vb. teknolojiler vasıtasıyla,
                otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik
                sağlayıcılar, reklam ağları, arama bilgi sağlayıcıları,
                teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek,
                kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve
                sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat
                işleme şartına dayanılarak işlenecektir.
              </p>
            </article>
            <article>
              <h4>
                Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla
                Aktarılabileceği
              </h4>
              <p>
                Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi
                yürütmek üzere hizmet aldığımız ve/veya verdiğimiz, sözleşmesel
                ilişki içerisinde bulunduğumuz, işbirliği yaptığımız, yurt içi
                ve yurt dışındaki 3. şahıslar ile kurum ve kuruluşlara ve talep
                halinde adli ve idari makamlara, gerekli teknik ve idari
                önlemler alınması koşulu ile aktarılabilecektir.
              </p>
            </article>
            <article>
              <h4>Kişisel Verileri İşlenen Kişi Olarak Haklarınız</h4>
              <p>
                KVKK madde 11 uyarınca herkes, veri sorumlusuna başvurarak
                aşağıdaki haklarını kullanabilir:
              </p>
              <ul>
                <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                <li>
                  Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,,
                </li>
                <li>
                  Kişisel verilerin işlenme amacını ve bunların amacına uygun
                  kullanılıp kullanılmadığını öğrenme,,
                </li>
                <li>
                  Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                  üçüncü kişileri bilme,,
                </li>
                <li>
                  Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                  bunların düzeltilmesini isteme,,
                </li>
                <li>
                  Kişisel verilerin silinmesini veya yok edilmesini isteme,,
                </li>
                <li>
                  (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel
                  verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,,
                </li>
                <li>
                  İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                  analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                  ortaya çıkmasına itiraz etme,,
                </li>
                <li>
                  Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep etme,
                  haklarına sahiptir.,
                </li>
              </ul>
              <p>
                Yukarıda sayılan haklarınızı kullanmak üzere info@iyziship.com
                üzerinden bizimle iletişime geçebilirsiniz.
              </p>
            </article>
            <article>
              <h4>İletişim</h4>
              <p>
                Sizlere hizmet sunabilmek amaçlı analizler yapabilmek için,
                sadece gerekli olan kişisel verilerinizin, işbu gizlilik ve
                kişisel verilerin işlenmesi politikası uyarınca işlenmesini,
                kabul edip etmemek hususunda tamamen özgürsünüz. Siteyi
                kullanmaya devam ettiğiniz takdirde kabul etmiş olduğunuz
                tarafımızca varsayılacak olup, daha ayrıntılı bilgi için bizimle
                info@iyziship.com e-posta adresi üzerinden iletişime geçmekten
                lütfen çekinmeyiniz.
              </p>
            </article>
            <article>
              <p className="company-info">
                <span>Ticaret Ünvanı:</span> İYZİ BİLİŞİM ANONİM ŞİRKETİ
              </p>
              <p className="company-info">
                <span>Adresi:</span>{" "}
                <a
                  href="https://goo.gl/maps/qNRaDs7V4fi5CtW2A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                  Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL
                  </span>
                </a>
              </p>
              <p className="company-info">
                <span>Telefon:</span> +90 (850) 532 49 94
              </p>
              <p className="company-info">
                <span>Fax:</span> +90 (212) 695 0 959
              </p>
              <p className="company-info">
                <span>E-posta:</span>{" "}
                <a rel="noreferrer" href="mailto:info@iyziship.com">
                  info@iyziship.com
                </a>
              </p>
              <p className="company-info">
                <span>Web sitesi:</span> <Link to={"/"}>www.iyziship.com</Link>
              </p>
            </article>
          </section>
        </main>
        <Footer />
      {/* </PageTransition> */}
    </>
  );
};

export default PrivacyPolicy;
