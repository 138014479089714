import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const MyWalletArrangementInfo = ({ amount, refNo }) => {
  
  return (
    <div className="my-wallet-arrangement-info">
      <div className="my-wallet-arrangement-info-item">
        <InfoIcon />
        <Typography>
          <strong>Açıklama:</strong> KDV'siz olacak şekilde toplam {amount} TL
          tutarında fatura keserek "Fatura Yükle” butonundan faturanızı
          yükleyiniz.
        </Typography>
      </div>
      <div className="my-wallet-arrangement-info-item">
        <InfoIcon />
        <Typography>
          <strong>Fatura Kesilecek Firma Bilgileri:</strong> İYZİ BİLİŞİM ANONİM
          ŞİRKETİ A.Ş. Koza Mah. 1644. Sok. No: 27 Bahçeşehir Mobilyacılar Sitesi 34519 ESENYURT / İSTANBUL - 4831238913/Güneşli VD
        </Typography>
      </div>
      <div className="my-wallet-arrangement-info-item">
        <InfoIcon />
        <Typography>
          <strong>Açıklama:</strong> Faturanın açıklama kısmında {refNo}{" "}
          numarayı ekleyiniz.
        </Typography>
      </div>
    </div>
  );
};

export default MyWalletArrangementInfo;
