import { Table, TableBody, TableContainer } from "@mui/material";
import MyWalletExtractTableHead from "./MyWalletExtractTableHead";
import MyWalletExtractTableBody from "./MyWalletExtractTableBody";
import { useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../../../api/axios";
import {
  extractCurrentPageState,
  extractDataState,
  extractTotalPageState,
  totalInvoicableAmountState,
} from "../../../atoms/myWallet";
import Errors from "../../../utils/Errors";

//TODO: Niyazi söylediğinde buraya 6.0 eklenecek!
const allowedDescriptionCodes = [
  3.0, 3.1, 4.0, 5.0, 8.0, 9.0, 10.1, 10.2, 14.0, 15.0, 16.1, 16.2, 17.0, 18.0, 19.0, 19.10, 20.1, 20.2,
  21, 22, 23, 24, 25
];

const MyWalletExtractTable = () => {
  const [extractData, setExtractData] = useRecoilState(extractDataState);
  const setExtractTotalPage = useSetRecoilState(extractTotalPageState);
  const setExtractCurrentPage = useSetRecoilState(extractCurrentPageState);
  const setTotalInvoicableAmount = useSetRecoilState(totalInvoicableAmountState);

  const getData = useCallback(() => {
    axios
      .get(`wallet/extrem-list`)
      .then((response) => {
        setExtractData(response.data.data);
        setExtractCurrentPage(response.data.currentPage);
        setExtractTotalPage(response.data.totalPages);
        setTotalInvoicableAmount(response.data?.invoiceable_balance_total);
      })
      .catch((error) => {
        Errors(error);
      });
  }, [setExtractData, setExtractCurrentPage, setExtractTotalPage, setTotalInvoicableAmount]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TableContainer
      sx={{
        backgroundColor: "var(--lighterBg)",
        borderRadius: "var(--inputBorderRadius)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table>
        <MyWalletExtractTableHead />
        <TableBody>
          {extractData
            ?.filter((item) => allowedDescriptionCodes.includes(Number(item.description_code)))
            .map((item, key) => (
              <MyWalletExtractTableBody key={key} item={item} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyWalletExtractTable;
